<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left">
        <el-form inline>
          <el-form-item label="日期：">
            <el-date-picker />
          </el-form-item>
          <el-form-item label="供应商：">
            <el-input />
          </el-form-item>
          <el-form-item label="商品：">
            <el-input />
          </el-form-item>
          <el-form-item label="仓库：">
            <el-input />
          </el-form-item>
          <el-form-item>
            <el-button>重置</el-button>
            <el-button type="primary">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="pageFucView_right">
        <el-button>导出</el-button>
      </div>
    </div>
    <div class="tableView">
      <span
          style="
          display: block;
          width: 100%;
          text-align: center;
          font-size: 30px;
          margin-top: 10px;
        "
      >采购汇总表（按供应商）</span
      >
      <span
          style="display: block; width: 100%; margin-bottom: 5px; color: #909399"
      >日期: 2022-11-01 至 2022-11-21</span
      >
      <el-table border>
        <el-table-column label="供应商"></el-table-column>
        <el-table-column label="商品编号"></el-table-column>
        <el-table-column label="商品名称"></el-table-column>
        <el-table-column label="规格型号"></el-table-column>
        <el-table-column label="单位"></el-table-column>
        <el-table-column label="仓库"></el-table-column>
        <el-table-column label="数量"></el-table-column>
        <el-table-column label="单价"></el-table-column>
        <el-table-column label="采购金额"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "puSummarySupply"
}
</script>

<style scoped>
.page {
  background-color: #fff;
  padding: 20px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageFucView_right {
  display: flex;
}
.el-form-item {
  margin-bottom: 0px;
  margin-right: 10px;
}

</style>